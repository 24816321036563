<div class="side-nav"
  sideBar>
  <div class="side-nav-inner">
    <div class="side-nav-logo">
      <a href=""
        [routerLink]="['/dashboard']">
        <div class="logo logo-dark"
          style="background-image: url('assets/images/logo/taxi-up-header.png')"></div>
        <div class="logo logo-white"
          style="background-image: url('assets/images/logo/taxi-up-header.png')"></div>
      </a>
      <div class="mobile-toggle side-nav-toggle">
        <a (click)="toggleSideNavCollapse()"
          href="javascript:void(0);">
          <i class="ti-arrow-circle-left"></i>
        </a>
      </div>
    </div>
    <ul class="side-nav-menu scrollable"
      [perfectScrollbar]>
      <li class="nav-item"
      *ngIf="isAdmin"
      routerLinkActive="active">
      <a class="mrg-top-30"
        [routerLink]="['/dashboard']">
        <span class="icon-holder">
          <i class="fa fa-tachometer"></i>
        </span>
        <span class="title">Dashboard</span>
      </a>
    </li>
      <li class="nav-item"
        *ngIf="isAdmin"
        routerLinkActive="active">
        <a 
          [routerLink]="['/dashboard/map']">
          <span class="icon-holder">
            <i class="fa fa-map"></i>
          </span>
          <span class="title">Mapa</span>
        </a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/drivers']">
          <span class="icon-holder">
            <i class="fa fa-id-card-o"></i>
          </span>
          <span class="title">Conductores</span>
        </a>
      </li>
      <li class="nav-item"
        *ngIf="isAdmin">
        <a [routerLink]="['/trips']">
          <span class="icon-holder">
            <i class="fa fa-road"
              aria-hidden="true"></i>
          </span>
          <span class="title">Viajes</span>
        </a>
      </li>
      <li class="nav-item"
        *ngIf="isAdmin">
        <a [routerLink]="['/passengers']">
          <span class="icon-holder">
            <i class="fa fa-users"></i>
          </span>
          <span class="title">Pasajeros</span>
        </a>
      </li>
      <li class="nav-item"
        *ngIf="isAdmin">
        <a [routerLink]="['/users']">
          <span class="icon-holder">
            <i class="fa fa-user-circle-o"></i>
          </span>
          <span class="title">Usuarios</span>
        </a>
      </li>
      <!-- <li class="nav-item">
                <a href="javascript:void(0);">
                    <span class="icon-holder">
                        <i class="fa fa-taxi"></i>
                    </span>
                    <span class="title">Unidades</span>
                </a>
            </li> -->
    </ul>
  </div>
</div>
