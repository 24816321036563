<div class="header navbar">
    <div class="header-container">
        <ul class="nav-left">
            <li>
                <a class="side-nav-toggle"
                    (click)="toggleSideNavCollapse()"
                    href="javascript:void(0);">
                    <i class="ti-view-grid"></i>
                </a>
            </li>
        </ul>
        <ul class="nav-right">
            <li class="user-profile dropdown">
                <a href=""
                    class="dropdown-toggle"
                    data-toggle="dropdown">
                    <!-- <img class="profile-img img-fluid" src="assets/images/user.jpg"> -->
                    <div class="user-info">
                        <span class="name pdd-right-5">{{currentUser?.displayName}}</span>
                        <i class="ti-angle-down font-size-10"></i>
                    </div>
                </a>
                <ul class="dropdown-menu">
                    <li class="logout-btn"
                        (click)="logout()">
                        <a>
                            <i class="ti-power-off pdd-right-10"></i>
                            <span>Salir</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
