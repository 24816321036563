<div class="side-panel" [ngClass]="{'side-panel-open' : isOpen}">
    <div class="side-panel-wrapper bg-white">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item active">
                <a class="nav-link" href="#tab1" role="tab" data-toggle="tab">
                    <span>Tab 1</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#tab2" role="tab" data-toggle="tab">
                    <span>Tab 2</span>
                </a>
            </li>
            <li class="nav-item ">
                <a class="nav-link" href="#tab3" role="tab" data-toggle="tab">
                    <span>Tab 3</span>
                </a>
            </li>
            <li class="panel-close">
                <a class="side-panel-toggle" href="javascript:void(0);" (click)="toggleSidePanelOpen()">
                    <i class="ti-close"></i>
                </a>
            </li>
        </ul>
        <div class="tab-content">
            <div id="tab1" role="tabpanel" class="tab-pane fade in active">
                <span>Tab 1</span>
            </div>
            <div id="tab2" role="tabpanel" class="tab-pane fade">
                <span>Tab 2</span>
            </div>
            <div id="tab3" role="tabpanel" class="tab-pane fade">
                <span>Tab 3</span>
            </div>
        </div>	
    </div>
</div>