import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserModel, AuthModel } from 'src/app/auth/models/auth.models';
import { environment } from 'src/environments/environment';
import { getHeaders } from 'src/app/utils/headers.functions';
import { ClientTypes, ClientRoles } from './auth.models';

const BASE_URL: string = environment.API_URL;


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<AuthModel>;

  public currentUser: Observable<AuthModel>;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<AuthModel>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): AuthModel {
    return this.currentUserSubject.value;
  }

  public login(user: UserModel, accessType: string): Observable<AuthModel> {
    return this.http.post<AuthModel>(`${BASE_URL}/auth/login`, user, {
      headers: {
        'Type-Client': accessType,
      }
    })
      .pipe(map(currentUser => this.setUser(currentUser)));
  }

  public logout(): void {
    localStorage.removeItem('currentUser');
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  public signUp(user: UserModel): Observable<AuthModel> {
    const headers: HttpHeaders = getHeaders();
    // TODO: Business Logic needs more work on this.
    user.roles = [ClientRoles.Admin];
    return this.http.post<AuthModel>(`${BASE_URL}/auth/signup`, user, { headers })
      .pipe(map(currentUser => this.setUser(currentUser)));
  }

  public handleUnauthorizedError(err: any) {
    if (err.error && err.error.status === 401) {
      this.logout();
      this.router.navigate(['/auth/sign-in']);
    }

    return throwError('Session Expired');
  }

  public createUser(user: UserModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/users`, user, { headers });
  }

  private setUser(user: AuthModel) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
    return user;
  }
}
