import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { TemplateService } from '../../services/template/template.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { ClientTypes, ClientRoles } from 'src/app/services/auth/auth.models';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

  isCollapse: boolean;
  isAdmin: boolean;

  constructor(
    private tplSvc: TemplateService,
    private router: Router,
    private authService: AuthenticationService
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (window.innerWidth < 992) {
          this.tplSvc.toggleSideNavCollapse(false);
        }
      }
    });
  }

  ngOnInit() {
    this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
    this.isAdmin = true;//this.authService.currentUserValue.roles.includes(ClientRoles.Admin);
  }

  toggleSideNavCollapse() {
    this.isCollapse = !this.isCollapse;
    this.tplSvc.toggleSideNavCollapse(this.isCollapse);
  }
}
