<footer class="content-footer">
    <div class="footer">
        <div class="copyright">
            <span>Copyright © 2020 <b class="text-dark">Taxi Up</b>. Todos los derechos reservados.</span>
            <span class="go-right">
                <a href="" class="text-gray mrg-right-15">Terminos y Condiciones</a>
                <a [routerLink]="['/privacy-policy']" class="text-gray" target="_blank">Política de Privacidad</a>
            </span>
        </div>
    </div>
</footer>
