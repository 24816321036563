import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateService } from '../../services/template/template.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { AuthModel } from 'src/app/auth/models/auth.models';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  public searchModel: string;
  public isCollapse: boolean;
  public isOpen: boolean;
  public searchActived: boolean = false;
  public currentUser: AuthModel;

  constructor(
    private tplSvc: TemplateService,
    private authService: AuthenticationService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    // this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
    // this.tplSvc.isSidePanelOpenChanges.subscribe(isOpen => this.isOpen = isOpen);
    // this.authService.currentUser.subscribe((authenticatedUser: AuthModel) => this.currentUser = authenticatedUser);
  }

//   logout(): void {
//     this.authService.logout();
//     this.router.navigateByUrl('/auth/sign-in');
//   }

//   toggleSideNavCollapse() {
//     this.isCollapse = !this.isCollapse;
//     this.tplSvc.toggleSideNavCollapse(this.isCollapse);
//   }

//   toggleSidePanelOpen() {
//     this.isOpen = !this.isOpen;
//     this.tplSvc.toggleSidePanelOpen(this.isOpen);
//   }

//   toggleSearch() {
//     this.searchActived = !this.searchActived;
//     console.log(this.searchActived)
//   }
}
