import { Routes } from '@angular/router';
// Guards
import { AuthGuard } from './auth/auth.guard';
// Layouts
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';
import { RoleGuard } from './auth/roles/role.guard';
import { PrivacyPolicyComponent } from './template/privacy-policy/privacy-policy.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: CommonLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        canLoad: [RoleGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'trips',
        canLoad: [RoleGuard],
        loadChildren: () => import('./trips/trips.module').then(m => m.TripsModule),
      },
      {
        path: 'drivers',
        loadChildren: () => import('./drivers/drivers.module').then(m => m.DriversModule)
      },
      {
        path: 'passengers',
        canLoad: [RoleGuard],
        loadChildren: () => import('./passengers/passenger.module').then(m => m.PassengerModule)
      },
      {
        path: 'users',
        canLoad: [RoleGuard],
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      },
    ]
  },
  {
    path: '',
    component: AuthenticationLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./auth/authentication.modules').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: '**',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  }
];
