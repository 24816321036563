import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard',
    templateUrl: './authentication-layout.component.html',
})
export class AuthenticationLayoutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void { }
}
