export enum ClientTypes {
  Admin = 'app=admin',
  Manager = 'app=manager',
  Driver = 'app=driver',
  User = 'app=user'
}

export enum ClientRoles {
  Admin = 'admin',
  User = 'user',
  Driver = 'driver'
}
