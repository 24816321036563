import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { ClientRoles } from 'src/app/services/auth/auth.models';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanLoad {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) { }

  canLoad(): Promise<boolean> | boolean {
    return this.checkUser();
  }

  checkUser(): Promise<boolean> | boolean {
    const currentUser = this.authService.currentUserValue;
    const isAdmin = true;//currentUser.roles.includes(ClientRoles.Admin);

    if (isAdmin) {
      return true;
    } else {
      this.router.navigateByUrl('/drivers');
      return false;
    }
  }
}
